import { NextPage } from 'next';
import { GuestGuard } from 'src/components/auth/guest-guard';
import LandingPage from 'src/components/v2/landing-page';

const SubdomainLandingPage: NextPage = () => {
  return <LandingPage />;
};

SubdomainLandingPage.getLayout = (page) => (
  <GuestGuard>
    {page}
  </GuestGuard>
);

export default SubdomainLandingPage;
